.class_label{
    margin-top:2px;
    font-size: 14px;
    font-weight: 500;
    /*margin-right: 5px;*/
}

.class_title{
    display: flex;
    align-items: center;
    justify-content: center;
    color:#07386b;
}
.class_switch{
    margin-left: 10px;

}

.class_modal{
    padding-top: 10px !important;
    padding-left: 32px !important;
    padding-right: 32px !important;
    padding-bottom: 32px;
}

.myContainerFlex {
    display: flex!important;
    justify-content: flex-end!important;
    margin-top: 20px;  
}

.button_customize{
    border-radius:0px!important;
}

.column-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px; /* Ajusta según tus necesidades */
    border: 1px solid #ccc; /* Añadido para visualizar mejor las columnas */
  }
  
  .class_label {
    flex-grow: 1;
    margin-right: 8px; /* Espaciado entre la etiqueta y el interruptor */
  }
  
  .class_switch {
    margin-left: 8px; /* Espaciado entre el interruptor y la etiqueta */
  }